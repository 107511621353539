.instructions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 200px 50px 50px; /* Added margin-top */
    background-color: #1a1a1a;
    min-height: 100vh;
    color: white;
}

.instructions-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 40px;
    color: #ffffff;
    text-align: center;
}

.button-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.docs-content {
    padding: 30px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.1);
    color: #ddd;
}

.docs-page-container {
    padding: 40px;
    max-width: 1100px;
    width: 100%; /* Full width */
    background: rgba(255, 255, 255, 0.08);
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    position: relative; /* Make sure the container's position is relative */
    margin-top: 50px;
}

p {
    max-width: 800px; /* Adjust the value to the desired width */
    margin: 0 auto; /* Center align the text within the container */
    line-height: 1.6; /* Optional: Adjust the line height for readability */
    padding: 10px 0; /* Optional: Add some padding to space out the paragraphs */
}

.card-3 {
    background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    text-align: center;
    transition: transform 0.3s ease;
}

.button-container button {
    background-color: #7f5ec0;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    margin: 10px;
    padding: 15px;
    text-align: center;
    transition: background-color .3s ease, transform .3s ease;
    width: 150px;
}

.button-container button:hover {
    background-color: #a48adb;
    transform: scale(1.05); /* Slight zoom on hover */
}

.guide-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.toggle-button {
    background-color: #333;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.toggle-button.active {
    background-color: #555;
}

.guide-content h3 {
    margin-top: 20px;
    font-size: 1.8rem;
    text-align: center;
}

ol {
    padding-left: 40px;
    line-height: 1.8; /* Increase line-height for better readability */
}

ul {
    padding-left: 60px;
    margin-top: 10px;
}

ul li {
    list-style-type: disc; /* Add bullet points for ul */
}

.in-game-settings {
    display: flex;
    flex-direction: column; /* Stack the images vertically */
    align-items: center;
}

.in-game-settings img {
    max-width: 60%; /* Make the images responsive */
    margin: 10px 0; /* Add some space between images */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.in-game-settings p {
    color: #ddd;
    font-size: 1rem;
    text-align: center;
    padding: 0px;
    margin: 5px;
    margin-bottom: 30px;
}

/* For smaller devices */
@media (max-width: 768px) {
    .in-game-settings img {
        max-width: 100%; /* Ensure images fit the container */
        margin-bottom: 20px;
    }

    .button-container {
        flex-direction: column;
    }

    .docs-page-container {
        width: calc(100% - 10px);
    }

    .docs-page-container {
        padding: 40px;
        max-width: 1100px;
        width: 100%; /* Full width */
        background: rgba(255, 255, 255, 0.08);
        border-radius: 20px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
        position: relative; /* Make sure the container's position is relative */
        margin-top: 50px;
    }
}
