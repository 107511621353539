/* Scope all styles under the .admin-dashboard class */
.admin-dashboard {
    display: flex;
    min-height: 100vh;
    background-color: #f0f0f0; /* Light background to differentiate content */
    font-family: 'Roboto', sans-serif;
    padding-top: 20px;
}

/* Sidebar Styling */
.admin-dashboard .sidebar {
    width: 250px;
    background-color: #1c1c1e;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.admin-dashboard .sidebar h2 {
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 30px;
}

.admin-dashboard .sidebar ul {
    list-style: none;
    padding: 0;
}

.admin-dashboard .sidebar ul li {
    margin-bottom: 20px;
}

.admin-dashboard .sidebar ul li a {
    color: #ccc;
    text-decoration: none;
    font-size: 1.1rem;
    padding: 10px 15px;
    display: block;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.admin-dashboard .sidebar ul li a:hover {
    background-color: #333;
    color: #fff;
}

/* Main Content Styling */
.admin-dashboard .main-content {
    margin-left: 250px;
    padding: 40px;
    background-color: #f9f9f9;
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 50px;
}

/* Search Bar Styling */
.admin-dashboard .search-bar {
    width: 50%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    background-color: #fff;
    color: #333;
}

/* Card Container Styling */
.admin-dashboard .card-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    color:black;
}

/* Individual Card Styling */
.admin-dashboard .card {
    width: calc(33.333% - 20px); /* Three cards in a row with consistent width */
    background-color: #f5f5f5; /* Light gray card background */
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    border: 1px solid #ddd;
    color: #333; /* Dark text color */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
}

/* Text inside the card */
.admin-dashboard .card h3, 
.admin-dashboard .card p {
    color: #333; /* Ensure the text is dark for good readability */
}

/* Adjustments to ensure all cards are the same size */
.admin-dashboard .card p {
    color: #555; /* A slightly lighter gray for secondary text */
    margin-bottom: 8px;
    line-height: 1.6;
    flex-grow: 1; /* Ensure text stretches the card */
}

.admin-dashboard .card h3 {
    color: #333; /* Header text color */
    font-size: 1.5rem;
    margin-bottom: 10px;
}

/* Button Styling */
.admin-dashboard .admin-button {
    background-color: #7f5ec0;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
    margin-right: 10px;
    margin-top: 10px;
    display: inline-block;
    text-align: center;
}

.admin-dashboard .admin-button:hover {
    background-color: #6b4ea6;
}

/* Responsive layout for smaller screens */
@media (max-width: 1200px) {
    .admin-dashboard .card {
        width: calc(50% - 20px); /* Two cards per row on medium screens */
    }
}

@media (max-width: 768px) {
    .admin-dashboard {
        flex-direction: column;
    }

    .admin-dashboard .sidebar {
        width: 100%;
        height: auto;
    }

    .admin-dashboard .main-content {
        margin-left: 0;
        padding: 20px;
    }

    .admin-dashboard .card {
        width: 100%; /* Full width on smaller screens */
    }

    .admin-dashboard .search-bar {
        width: 80%; /* Adjust search bar width for smaller screens */
    }
}
