.App {
  position: relative;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: inherit; 
  padding-top: 80px; 
  overflow: hidden; /* Ensure no overflow */
}

.background-overlay {
  position: fixed; /* Change to fixed to make it scroll */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./images/controllerLogo.png') no-repeat center center;
  background-size: 600px 600px; /* Adjust the size of your logo */
  filter: blur(8px); /* Apply blur effect */
  opacity: 0.2; /* Adjust opacity if needed to make the image more visible */
  z-index: -1; /* Ensure it stays behind the content */
  pointer-events: none; /* Ensure it does not interfere with other elements */
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(15deg, #1a1a1a 0%, #1f1f1f 100%);
  z-index: -2; /* Ensure it stays behind the blurred image */
}


html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; 
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
}

.section-1::after {
  display: none;
}

.section-4::before {
  display: none;
}

.section-1 {
  min-height: 100vh; /* Ensure it takes the full viewport height */
  position: relative;
}

.section-2 {
  min-height: 75vh; /* Ensure it takes the full viewport height */
  background: rgba(0, 0, 0, 0.1); /* Add some background color to distinguish sections */
  padding-top: 50px;
}

.section-3, .section-4 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
}


.testimonials-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  font-size: 1.2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  animation: slideIn 1s forwards;
  flex: 1;
  max-width: 300px;
  min-width: 300px;
  box-sizing: border-box;
  position: relative;
}

.testimonial-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
}

.testimonial-card:nth-child(1) {
  animation-delay: 0.3s;
}

.testimonial-card:nth-child(2) {
  animation-delay: 0.6s;
}

.testimonial-card:nth-child(3) {
  animation-delay: 0.9s;
}

@keyframes slideIn {
  from {
      transform: translateY(50px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

.testimonial-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.testimonial-username {
  font-size: 1rem;
  color: #ccc;
  margin-bottom: 10px;
  display: block;
}

.testimonial-text {
  font-size: 1rem;
  line-height: 1.5;
}


li.status-item {
  margin-top: 10px;
}

.Typewriter__wrapper {
  border-right: .1em solid transparent;
  animation: blink .7s infinite;
  font-size: 1.5rem;
  font-weight: bold;
  opacity: 0.5;;
}

.subtitle-container {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.15); /* Slightly reduce opacity for a more glassy look */
  backdrop-filter: blur(15px); /* Increase the blur to make it more pronounced */
  border-radius: 10px;
  padding: 20px;
  color: #ffffff; /* Ensure the text color stands out */
  font-size: 1.2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  animation: slideIn 1s forwards;
}

.testimonial-card:nth-child(1) {
  animation-delay: 0.3s;
}

.testimonial-card:nth-child(2) {
  animation-delay: 0.6s;
}

.testimonial-card:nth-child(3) {
  animation-delay: 0.9s;
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.vertical-nav {
  position: fixed;
  display: inline-block;
  top: 45%;
  transform: translateY(-50%);
  right: 17px;
  border-radius: 25px;
  backdrop-filter: blur(9px);
  box-shadow: 0 .75rem 2rem 0 rgba(0, 0, 0, .1);
  background: rgba(255, 255, 255, .1);
  border: 1px solid rgba(255, 255, 255, .125);
  overflow: hidden;
  z-index: 1000;
  padding: 5px;
}

.nav-dot {
  width: 12px;
  height: 12px;
  background-color: #a48adb;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 5px;
  margin-bottom: 5px;
}

.section-header-badge{
  display: inline-block;
  background-color: #7f5ec0;
  padding: 4px 12px;
  border-radius: 16px;
  margin-bottom: 16px;
  width: 100px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 10px;
}

.section-header-badge:hover {
  background-color: #a48adb;
  scale: 1.1;
  transition: ease .5s;
}

.nav-dot:hover {
  background-color: rgba(255, 255, 255, 0.8);
}


.faq-container {
  text-align: center;
  color: white;
  max-width: 800px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.faq-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.faq-item {
  margin: 10px 0;
  text-align: left;
}

.faq-question {
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 20px;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s smooth;
  font-size: 1rem;
  padding: 0 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin-top: 5px;
}

.faq-answer.active {
  max-height: 100px;
  padding: 20px;
  margin-bottom: 20px;
  padding-bottom: 60px;
  background: #a48adb; 
  background: rgb(145, 68, 218, 0.3)
}

@media (min-width: 430px) and (max-width: 1199px) {
  .faq-answer .active {
    padding: 40px;
  }
}

.faq-arrow{
  color: #7f5ec0;
}

.status-item {
  position: relative;
  font-size: .85rem;
  margin-left: 25px;
  left: 0;
  padding: 4px;
  list-style: none;
  display: flex;
  transition: .1s ease;
}

.status-item::before {
  content: "";
  background-color: transparent;
  position: absolute;
  left: -20px;
  top: 6.4px;
  width: 5px;
  height: 11px;
  border-bottom: 2px solid #8a6bc8;
  border-right: 2px solid #8a6bc8;
  filter: drop-shadow(0 0 5px #a48adb);
  opacity: .5;
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.body {
  opacity: 0;
  font-family: "Lexend Deca";
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(15deg, #1a1a1a 0%, #1f1f1f 100%);
  transition: transform 1.6s, opacity 3.2s;
}
.main-content {
  text-align: center;
  width: 100%; /* Ensure the main content takes full width */
  margin-top: -200px;
}

.title-container {
  text-align: center;
  color: white;
  width: 100%;
}

.title {
  position: relative;
  color: white;
  font-weight: 700;
  font-size: clamp(3rem, 15vw, 6.5rem);
  text-align: center;
  justify-content: center;
  perspective: 1000px;
  text-decoration: none;
  text-shadow: 4px 3px #3d3d3d;
  cursor: pointer;
  transition: .5s ease;
  width: 100%;
}

.title:hover {
  transform: scale(1.1);
}

.subtitle {
  font-size: clamp(1.5rem, 5vw, 1.8rem);
  opacity: 0.8;
  color: white;
}

.purchase-button {
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  width: 100px;
  margin: 6px;
  text-align: center;
  padding-bottom: 4px;
  text-decoration: none;
  background-color: transparent /*#1b5bb3*/;
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  transition: all .7s;
  vertical-align: top;
  box-shadow: rgb(255 255 255 / 20%) 0 13px 15px inset, rgb(0 0 0 / 10%) 0 3px 5px, rgb(0 0 0 / 10%) 0 1px 13px;
  z-index: 10;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Home */
.status-card img {
  width: 70%;
}

/* Dashboard */
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #1a1a1a;
  min-height: 100vh;
  color: #fff;
}

.dashboard p,
.product strong,
.feature p {
  font-size: 0.9rem;
}

.welcome-message {
  margin-top: 200px;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  margin: 20px 0;
  padding: 30px;
  width: 100%;
  max-width: 700px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.user-password {
  font-size: 1.2rem;
  margin-right: 10px;
  background-color: #333; /* Slight background behind password */
  padding: 5px 10px;
  border-radius: 8px;
}

.profile-info,
.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  gap: 15px;
}

.discord-avatar {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  border: 2px solid #7f5ec0;
}

.password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.password-container p {
  font-weight: bold;
}

.user-password {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.user-password span {
  display: inline-block;
  filter: blur(8px);
  transition: filter 0.3s ease;
}

.user-password.show span {
  filter: none;
}

.toggle-button {
  background-color: #7f5ec0; /* Match button color with the overall theme */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}


.toggle-button:hover {
  background-color: #a48adb; /* Darken on hover */
}

.product-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.product {
  background: rgba(255, 255, 255, 0.08);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(127, 94, 192, 0.3);
}

.product:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.product img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.product h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #e0e0e0;
}

.product p {
  font-size: 1rem;
  color: #c0c0c0;
  margin: 5px 0;
}

.buy-button,
.reset-button,
.download-button{
  border-radius:6px;
}

.buy-button,
.activate-button,
.reset-button,
.download-button{
  background-color: #7f5ec0;
  border: none;
  border-radius: 6px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px 0;
  width: calc(30% - 10px);
}

.buy-button:hover,
.activate-button:hover,
.download-button:hover {
  background-color: #a48adb;
}

.buy-button,
.activate-button,
.reset-button,
.download-button
{
  flex: 1;
  min-width: 60px;
}

.buttons-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

/* buy */
.features-container {
  /* margin: 50px 0; */
  text-align: center;
}

.features-title {
  font-size: 28px;
  margin-bottom: 30px;
}

.features-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns layout */
  gap: 20px;
  max-width: 1200px; /* Adjusted to allow for three cards per row */
  margin: 0 auto;
}

.feature {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.feature h3 {
  margin-top: 15px;
  font-size: 20px;
}

.feature p {
  margin-top: 10px;
  font-size: 16px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .features-list {
      grid-template-columns: 1fr; /* Single column for smaller screens */
  }

  .feature {
      margin-bottom: 20px;
  }
}

/* terms of service */
.tos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 30px;
  margin: 50px auto;
  max-width: 850px;
  background: rgba(255, 255, 255, 0.05); /* Subtle transparent background */
  border-radius: 10px;
  backdrop-filter: blur(10px); /* Adding a slight blur for a glassmorphism effect */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Light border for definition */
}

.tos-header {
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}

.tos-content {
  font-size: 18px;
  line-height: 1.8;
  text-align: left;
  color: #f0f0f0; /* Light font color to contrast with subtle background */
}

.tos-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.tos-content li {
  margin-bottom: 12px;
}

.tos-content p {
  font-size: 18px;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .tos-container {
    padding: 30px 20px;
    margin: 30px 10px;
  }

  .tos-header {
    font-size: 28px;
  }

  .tos-content {
    font-size: 16px;
  }
}

.tos-content p{
  text-align: center;
  font-size: 0.9rem;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.modal-content h2 {
  margin-bottom: 20px;
  color: #fff;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #7f5ec0;
  background-color: #2a2a2a;
  color: #fff;
}

.admin-button {
  background-color: #6e57e0; /* Consistent with the theme */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.admin-button:hover {
  background-color: #563da0; /* Darken on hover */
}

.cancel-button {
  background-color: #fd003f;
  border: none;
  border-radius: 6px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px 0;
  width: calc(30% - 10px);
  margin-left: 10px;
}

.activate-button:hover, .cancel-button:hover {
  background-color: #a48adb;
}

/* Status Panel Styles */
.status-panel-container {
  text-align: center;
  margin: 50px 0;
}

.section-header-badge {
  margin-bottom: 20px;
}

.status-title {
  font-size: 28px;
  color: #ffffff;
}

.status-panel {
  display: flex; 
  justify-content: center; 
  gap: 20px; 
  flex-wrap: wrap; 
}

.status-card {
  background-color: #1c1c1e;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  text-align: center;
  flex: 1 1 calc(33.33% - 40px);
}

.status-card-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.status-card-title {
  font-size: 18px;
  color: #ffffff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .status-card {
      flex: 1 1 calc(50% - 40px); /* On medium screens, cards take up half the width */
  }
}

@media (max-width: 480px) {
  .status-card {
      flex: 1 1 100%; /* On small screens, cards take up full width */
  }
}


/* Header */
/* General Header Styling */
.header {
  width: 100%;
  display: flex;
  justify-content: center; /* Centers the header content */
  align-items: center;
  padding: 10px 0;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}


.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

nav {
  opacity: 100;
  transition: visibility 0.3s, opacity 0.3s ease;
}

.header-content {
  display: flex;
  align-items: center;
  width: 60%;
  max-width: 600px; /* Optional: set a max-width for the header content */
  justify-content: space-between;
  padding: 10px 20px;
  background: rgba(3, 0, 20, 0.1);
  border-radius: 9999px;
}

.logo {
  width: 50px;
  height: 50px;
}

/* Hamburger Menu */
.hamburger {
  display: none; /* Hidden by default, will be shown in mobile view */
  flex-direction: column;
  cursor: pointer;
  z-index: 1001;
  position: absolute; /* Position it absolutely */
  right: 20px; /* Align to the right side */
  top: 50%; /* Align to the middle of the header */
  transform: translateY(-50%); /* Center it vertically */
}

.hamburger-bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.3s;
}

/* Navigation */
.nav-visible {
  visibility: visible;
  opacity: 1;
}

.logo-title {
  visibility: hidden;
  width: 0px;
}

/* Adjust padding and layout for menu */
nav ul {
  display: flex;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul li a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: #7f5ec0;
}

.customer-panel {
  background-color: #7f5ec0;
  border-radius: 20px;
  padding: 8px 16px;
}

.customer-panel:hover {
  background-color: #a48adb;
}

/* Mobile Menu */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  
.testimonials{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  max-width: 500px;
}
  nav {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s ease;
  }

  .logo-title {
    font-size: 1.1rem;
    visibility: visible;
    width: auto;
    color: white; /* Ensure the text color stands out */
    font-weight: bold; /* Make the text bold */
    text-transform: uppercase; /* Transform text to uppercase */
    letter-spacing: 1px; /* Add some spacing between letters */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and transform */
  }

  .logo-title:hover {
    color: #7f5ec0; /* Change color on hover */
    transform: scale(1.1); /* Slightly enlarge the text on hover */
  }

  nav ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    padding: 20px;
    gap: 15px;
    z-index: 100;
  }

  nav ul li {
    text-align: center;
    margin: 10px 0;
  }

  nav ul li a {
    padding: 10px 0;
  }

  nav.nav-visible ul {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(15deg, #1a1a1a 0%, #1f1f1f 100%);
  color: white;
}

.not-found-title {
  font-size: 6rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px #7f5ec0;
}

.not-found-text {
  font-size: 1.5rem;
  margin-bottom: 30px;
  opacity: 0.8;
}

.not-found-link {
  padding: 10px 20px;
  background-color: #7f5ec0;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  backdrop-filter: blur(10px);
  transition: background-color 0.3s;
}

.not-found-link:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

@keyframes logo-rotate {
    0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}


nav ul {
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
  list-style: none;
}

nav ul li a {
  color: rgba(255, 255, 255, 0.7);
  padding: 8px 16px;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 14px;
}

nav ul li a:hover {
  color: #7f5ec0;
}

nav ul li .customer-panel {
  background-color: #7f5ec0;
  border-radius: 20px;
}

nav ul li .customer-panel:hover {
  background-color: #a48adb;
  color: #ffff;
}

nav ul {
  display: flex;
  gap: 15px;
}

nav ul li {
  list-style: none;
  color: white;
  cursor: pointer;
}

/* Title */
.title-container {
  text-align: center;
  color: white;
}

.title {
  font-size: 4rem;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.title:hover {
  transform: scale(1.1);
}


.purchase-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  animation: buttonAnimation 1s infinite alternate;
  background-color: #8a6bc8;
}

@keyframes buttonAnimation {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5px);
  }
}

/* Scroll Arrow */
.scroll-arrow-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 1.5rem;
  background: rgba(255, 255, 255, .05);
  backdrop-filter: blur(0px);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, .09);
  border: 1px solid rgba(255, 255, 255, .125);
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center; 
  align-items: center; 
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  animation: pulse 1.5s infinite;
  transition: .15s ease-in-out;
}

.scroll-arrow {
  font-size: 1.5rem;
  color: white;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .1), 0 0 0 20px rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0), 0 0 0 20px rgba(255, 255, 255, .1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0), 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

/* Games Pages */
.docs-page-container:hover {
  transform: scale(1.02);
}

.status-card-content {
  margin-top: 10px;
  text-align: center;
}

.status-card-features {
  list-style-type: none; /* Removes bullet points */
  padding: 0;
  margin-top: 10px;
}

.status-card-feature {
  font-size: 14px;
  color: #ddd;
  margin-bottom: 5px;
}

/* Documentation Header */
.docs-header {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 25px;
}

/* Guide Buttons */
.guide-button {
  padding: 12px 24px;
  background-color: #7f5ec0;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.guide-button:hover {
  background-color: #a48adb;
  transform: translateY(-3px);
}

/* Documentation Content */
.docs-content {
  padding: 30px;
  background: rgba(0, 0, 0, 0.15); /* Darker glassy background */
  border-radius: 15px;
  box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.1);
  color: #ddd;
}

.docs-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ffffff;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
}

.docs-content p {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #e0e0e0;
  margin-bottom: 15px;
  text-align: left;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .guide-button {
      padding: 10px 20px;
      font-size: 0.9rem;
  }

  .docs-content h1 {
      font-size: 2rem;
  }

  .docs-content p {
      font-size: 1rem;
  }
}
/* Status Panel */
.status-panel {
  /* display: inline-grid; */
  border: 1px solid rgba(255, 255, 255, .125);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, .06);
  background: rgba(255, 255, 255, .05);
  grid-template-columns: auto;
  border-radius: 2.5rem;
  padding: 20px;
  overflow: hidden;
  transition: .75s ease;
}

.status-title {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 20px;
  font-family: inherit; 
  margin: 0 auto;
}

.status-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.status-list ul {
  list-style: none;
  padding: 0;
  margin: 0 20px;

}

.status-list li {
  font-size: 1rem;
  color: white;
  padding: 5px 0;
  font-family: inherit; /* Inherit the font-family from html/body */
  margin-right: 10px;
}

 .status-panel::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
  z-index: -1;
}

.status-panel::before {
margin-left:100px;
  content: "";
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
  z-index: -1;
}

/* login */
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: white;
}

.login-box {
  position: relative;
  background: rgba(255, 255, 255, .05);
  backdrop-filter: blur(9px);
  box-shadow: 0 .75rem 2rem 0 rgba(0, 0, 0, .1);
  border-radius: 2.5rem;
  border: 1px solid rgba(255, 255, 255, .125);
  margin: 17px;
  width: calc(33.33% - 34px);
  min-width: 250px;
  max-width: 300px;
  overflow: hidden;
  transition: .5s ease;
  user-select: none;
  padding: 40px;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  display: inline-flex;
  line-height: 10px;
  width: 95%;
  padding: 9px 0;
  padding-left: 5%;
  margin-top: 3px;
  margin-bottom: 14px;
  border: none;
  outline: none;
  border-radius: 25px;
  color: rgba(255, 255, 255, .6);
  background-color: rgba(0, 0, 0, .125);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.discord-button {
  background: #7f5ec0;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}


.discord-button:hover {
  background: #a48adb;
}

.circle-container {
  position: absolute;
  bottom: 20px; 
  right: 20px; 
}

.circle {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 1.5rem;
  right: 1rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, .05);
  box-shadow: 0 .75rem 2rem 0 rgba(0, 0, 0, .1);
  border: 1px solid rgba(255, 255, 255, .125);
  z-index: 1000;
}


@media (min-width: 600px) {
  .status-panel:hover {
      transform: scale(1.01) perspective(1000px) rotateX(5deg);
      margin-bottom: 6px;
  }
}

@keyframes pulseEffect {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0.3;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
}

li.status-item:hover{
  color: #a48adb;
  transform: scale(1.1);
  transition: 0.4s ease;
  font-weight: bold;
}

::-webkit-scrollbar {
  background: linear-gradient(315deg, #162b53 0%, #0e3c67 100%);
  width: .35rem;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, .35);
  backdrop-filter: blur(900px);
  box-shadow: 0 .75rem 2rem 0 rgba(0, 0, 0, .1);
  border: 1px solid rgba(255, 255, 255, .125);
  border-radius: 50px;
}

/* Sellpass-modal */
.sellpass-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  z-index: 10;
}

.EmbedProduct_sellpassEmbedClose__VUaO_ {
  visibility: hidden !important;
  display: none !important;
}

.close-modal:hover {
  color: #ff0000; /* Changes color when hovered */
}

.sellpass-iframe-wrapper {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity .2s linear;
}

.sellpass-iframe-wrapper.show {
  opacity: 1;
}

.sellpass-iframe-backdrop {
  background: #00000075;
  backdrop-filter: blur(3px);
  width: 100%;
  height: 100%;
  position: absolute;
}

.docs-page-container:hover {
  transform: scale(1.00);
}

.sellpass-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@keyframes lds-ring {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.sellpass-spinner div{
  visibility: hidden;
}
