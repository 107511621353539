

.buy {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.card-2-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
}

.card-2 {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  transition: transform 0.3s ease;
}

.card-2:hover {
  transform: scale(1.05);
}

.card-2 img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.package-selection {
  margin-top: 20px;
}

.package-selection select {
  display: inline-flex;
  line-height: 1.5;
  width: 95%;
  padding: 9px 0;
  padding-left: 5%;
  margin-top: 3px;
  margin-bottom: 14px;
  border: none;
  outline: none;
  border-radius: 25px;
  width: 200px;
  color: rgba(255, 255, 255, .6);
  background-color: rgba(0, 0, 0, .125);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.package-selection select option {
  color: black; /* Change text color of options */
}

.cart-button {
  background-color: #7f5ec0;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cart-button:hover {
  background-color: #a48adb;
}

.features-container, .testimonials-container {
  margin-top: 50px;
  text-align: center;
}

.features-title, .testimonials-title {
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 50px;
}

.features-list,
.features-list-2 {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.features-list-2 {
margin-top: 20px;
}

.feature {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  width: 250px;
  text-align: center;
}

.testimonials {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  width: 250px;
  text-align: center;
}

.testimonial-header {
  font-weight: bold;
}

.testimonial-username {
  font-size: 0.9rem;
  color: #ccc;
  margin-bottom: 10px;
}

.testimonial-text {
  font-size: 0.9rem;
}

.footer {
  background: #1a1a1a;
  color: #fff;
  padding: 10px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer a {
  color: #a48adb;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
